const FeedInFunc = () => {
  
  const comFeed = document.querySelectorAll('.comFeed');
  let sH = document.documentElement.clientHeight;
  let gap = sH * 0.7;

  window.addEventListener('scroll', () => {
    for (let i = 0; i < comFeed.length; i++) {
      let scroll = window.pageYOffset;
      let comFeedTop = comFeed[i].getBoundingClientRect().top + scroll;
      if (scroll > (comFeedTop - gap)) {
        comFeed[i].classList.add('act');
      }
    }
  });
  
}
export default FeedInFunc;